
.validated-table {
    color: #c10606 !important;
  }
  
  .validated-element {
    color: #c10606 !important;
  }
  
  .validated-element-margin {
    color: #c10606 !important;
    margin-top: -20px !important;
    margin-bottom: 20px !important;
  }

  .validated-element--hidden .validated-element-margin--hidden{
    visibility: hidden;
  }
  
  .validated-element-margin--hidden {
    visibility: hidden;
  }
  
  .label--for-text{
   color:  #c10606 !important;
   padding-left: 5px;
   padding-top: 5px;
  }
  
  .hidden {
    visibility: hidden;
}